<template>
    <el-scrollbar class="seller-scrollbar">
        <el-card class="seller-card">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'marketingtools' }">{{ $t('seller.breadcrumb.marketPlatform') }}</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ name: 'couponList', query: { type: 3 }}">{{ $t('seller.breadcrumb.coupon') }}</el-breadcrumb-item>
                <el-breadcrumb-item>{{ $t('seller.breadcrumb.couponInfo') }}</el-breadcrumb-item>
            </el-breadcrumb>
        </el-card>
            <el-form :model="couponForm" :rules="couponRules" ref="couponForm" size="small" label-width="260px">
                <el-card class="seller-card">
                    <template slot="header">{{ $t('seller.coupon.sharersCoupon') }}</template>
                    <el-form-item :label="$t('seller.formItem.name') + ':'">{{ couponInfo.name }}</el-form-item>
                    <el-form-item :label="$t('seller.formItem.useTime') + ':'">
                        {{ couponInfo.use_begin_time | parseTime() }}{{ $t('seller.unit.to') }}{{ couponInfo.use_end_time | parseTime() }}
                    </el-form-item>
                    <el-form-item :label="$t('seller.tableHead.type') + ':'">{{ couponInfo.sub_type | typeFilter($t('seller.filters.couponType')) }}</el-form-item>
                    <el-form-item :label="$t('seller.formItem.goodsRange') + ':'" prop="goods_ids"
                        v-if="couponInfo.sub_type == 2">
                        <el-button type="primary" @click="handleGoodsShow(1)">{{ $t('seller.actions.selectGoods') }}</el-button>
                        <span>（{{ $t('seller.actions.selected') }}{{ couponForm.goods_ids.length }}{{ $t('seller.unit.pcs') }}）</span>
                        <span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips4') }}</span>
                    </el-form-item>
                    <el-form-item :label="$t('seller.formItem.denomination') + ':'">{{ couponInfo.money }}{{ $t('seller.unit.yuan') }}</el-form-item>
                    <el-form-item :label="$t('seller.formItem.preferentialWay') + ':'">{{ $t('seller.unit.full') }}{{ couponInfo.quota }}{{ $t('seller.unit.yuan') + $t('seller.unit.use') }}</el-form-item>
                    <el-form-item v-if="$route.params.type === 'edit'"
                        :label="$t('seller.formItem.circulation') + ':'" prop="give_num">
                        <el-input class="w240" v-model="couponForm.give_num"
                            oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                        <span class="ml10">{{ $t('seller.unit.sheet') }}</span>
                        <span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips13') }}</span>
                    </el-form-item>
                    <el-form-item v-else :label="$t('seller.formItem.circulation') + ':'">
                        {{ couponInfo.give_num }}{{ $t('seller.unit.sheet') }}
                    </el-form-item>
                    <el-form-item :label="$t('seller.formItem.limitPerPerson') + ':'">
                        {{ couponInfo.limit_give_num }}{{ $t('seller.unit.sheet') }}
                    </el-form-item>
                    <el-form-item :label="$t('seller.formItem.shareNum') + ':'">
                        {{ couponInfo.share_num }}{{ $t('seller.unit.sheet') }}
                    </el-form-item>
                </el-card>
                <el-card v-if="couponInfo.sub_coupon" class="seller-card">
                    <template slot="header">{{ $t('seller.coupon.sharedByCoupon') }}</template>
                    <el-form-item :label="$t('seller.formItem.name') + ':'">{{ couponInfo.name }}</el-form-item>
                    <el-form-item :label="$t('seller.formItem.useTime') + ':'">
                        {{ couponInfo.sub_coupon.use_begin_time | parseTime() }}{{ $t('seller.unit.to') }}{{ couponInfo.sub_coupon.use_end_time | parseTime() }}
                    </el-form-item>
                    <el-form-item :label="$t('seller.tableHead.type') + ':'">{{ couponInfo.sub_coupon.sub_type | typeFilter($t('seller.filters.couponType')) }}</el-form-item>
                    <el-form-item :label="$t('seller.formItem.goodsRange') + ':'" prop="sub_coupon.goods_ids"
                        v-if="couponInfo.sub_coupon.sub_type == 2">
                        <el-button type="primary" @click="handleGoodsShow(2)">{{ $t('seller.actions.selectGoods') }}</el-button>
                        <span>（{{ $t('seller.actions.selected') }}{{ couponForm.sub_coupon.goods_ids.length }}{{ $t('seller.unit.pcs') }}）</span>
                        <span class="tips"><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips4') }}</span>
                    </el-form-item>
                    <el-form-item :label="$t('seller.formItem.denomination') + ':'">{{ couponInfo.sub_coupon.money }}{{ $t('seller.unit.yuan') }}</el-form-item>
                    <el-form-item :label="$t('seller.formItem.preferentialWay') + ':'">{{ $t('seller.unit.full') }}{{ couponInfo.sub_coupon.quota }}{{ $t('seller.unit.yuan') + $t('seller.unit.use') }}</el-form-item>
                    <el-form-item v-if="$route.params.type === 'edit'"
                        :label="$t('seller.formItem.circulation') + ':'" prop="sub_coupon.give_num">
                        <el-input class="w240" v-model="couponForm.sub_coupon.give_num"
                            oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                        <span class="ml10">{{ $t('seller.unit.sheet') }}</span>
                        <span class="tips" v-if="couponForm.give_num > couponInfo.give_num"><i
                                class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips11') }}
                            ({{ couponForm.give_num * couponInfo.share_num * 1.5 }}{{ $t('seller.unit.sheet') }})</span>
                        <span class="tips" v-else><i class="el-icon-warning"></i>{{ $t('seller.coupon.paneTips13') }}</span>
                    </el-form-item>
                    <el-form-item v-else :label="$t('seller.formItem.circulation') + ':'">
                        {{ couponInfo.sub_coupon.give_num }}{{ $t('seller.unit.sheet') }}
                    </el-form-item>
                    <el-form-item :label="$t('seller.formItem.limitPerPerson') + ':'">
                        {{ couponInfo.sub_coupon.limit_give_num }}{{ $t('seller.unit.sheet') }}
                    </el-form-item>
                </el-card>
            </el-form>
            <el-dialog :title="$t('seller.dialogTitle.goods')" width="900px" :visible.sync="dialogVisibleGoods"
                :before-close="handleCloseGoods" custom-class="seller-dialog">
                <el-tabs v-model="activeName" type="card" @tab-click="handleClickGoods">
                    <el-tab-pane v-for="(item, index) in dialogGoodsNav" :key="index" :label="item.label"
                        :name="item.value"></el-tab-pane>
                </el-tabs>
                <el-scrollbar class="seller-dialog-scrollbar">
                    <el-form v-if="activeName == 0" size="mini" :inline="true">
                        <el-form-item>
                            <el-select v-model="screenForm.store_cate_id" @change="handleSeach">
                                <el-option v-for="(item, index) in shopCateOption" :key="index"
                                    :label="item.label" :value="item.value"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="screenForm.goods_name"
                                :placeholder="$t('seller.placeholder.babyName')">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="screenForm.goods_code"
                                :placeholder="$t('seller.placeholder.babyCode')">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="screenForm.goods_id"
                                :placeholder="$t('seller.placeholder.babyId')">
                            </el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handleSeach">{{ $t('seller.actions.search') }}</el-button>
                        </el-form-item>
                    </el-form>
                    <el-table v-loading="listLoading" ref="goodsTable" :data="goodsList" style="width: 100%;"
                        :row-key="getRowKey" @selection-change="handleSelectionChange" class="seller-table">
                        <el-table-column type="selection" :reserve-selection="true" :selectable="onSelectable"
                            width="45">
                        </el-table-column>
                        <el-table-column :label="$t('seller.tableHead.babyDescribe')" min-width="220px">
                            <template slot-scope="scope">
                                <div class="goods-item">
                                    <img class="pic" :src="scope.row.default_image" alt="">
                                    <div class="item-mian">
                                        <div class="title">{{ scope.row.goods_name }}</div>
                                        <div class="number">
                                            {{ $t('seller.coupon.goodsCode') + ':' }}{{ scope.row.goods_code ? scope.row.goods_code : '-' }}
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column :label="$t('seller.tableHead.unitPrice')">
                            <template slot-scope="scope">￥{{ scope.row.price }}</template>
                        </el-table-column>
                        <el-table-column :label="$t('seller.tableHead.stock')" prop="stock"
                            align="center"></el-table-column>
                        <el-table-column :label="'30' + $t('seller.unit.day') + $t('seller.tableHead.totalSales')"
                            prop="sales" align="center">
                            <template slot-scope="scope">{{ scope.row.sales || 0 }}</template>
                        </el-table-column>
                    </el-table>
                    <pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr"
                        :limit.sync="pageSize" @pagination="getGoodsList"></pagination>
                </el-scrollbar>
                <div slot="footer">
                    <el-button size="small" @click="handleCloseGoods">{{ $t('seller.actions.cancel') }}</el-button>
                    <el-button type="primary" size="small" @click="handleToggleSelecting">{{ $t('seller.actions.confirm') }}</el-button>
                </div>
            </el-dialog>
            <div class="page-bottom">
                <el-button @click="$router.go(-1)">{{ $t('seller.actions.backList') }}</el-button>
                <el-button type="primary" @click="handleSave" v-if="$route.params.type === 'edit'">{{ $t('seller.actions.save') }}</el-button>
            </div>
    </el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { fetchCoupon, getCouponGoods, updateCoupon } from '@/api/seller/coupon';
export default {
    components: { Pagination },
    filters: {
        targetFilter(type, targetMap) {
            let option = []
            targetMap.forEach(item => {
                option.push(item.label)
            })
            return option[type]
        }
    },
    data() {
        var validateGoods = (rule, value, callback) => {
            if (value && value.length > 0) {
                callback()
            } else {
                callback(new Error(this.$t('seller.validator.minSelectGoods')))
            }
        }
        var validateGiveNum = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('seller.validator.circulationMsg')))
            } else if (parseInt(value) < this.couponInfo.give_num) {
                callback(new Error(this.$t('seller.placeholder.positiveInteger')))
            } else if (parseInt(value) > 100000) {
                callback(new Error(this.$t('seller.validator.circulationMax')))
            } else {
                callback();
            }
        }
        var validateSubGiveNum = (rule, value, callback) => {
            if (value === '') {
                callback(new Error(this.$t('seller.validator.circulationMsg')))
            } else if (this.couponForm.give_num && parseInt(value) < this.couponForm.give_num * this.couponInfo.share_num * 1.5) {
                callback(new Error(this.$t('seller.validator.couponGiveNum') + this.couponForm.give_num * this.couponInfo.share_num * 1.5 + this.$t('seller.unit.sheet')))
            } else {
                callback()
            }
        }
        return {
            datetimes: '',
            couponInfo: {},
            couponForm: {
                coupon_id: 0,
                goods_ids: [],
                give_num: '',
                limit_give_num: '',
                sub_coupon: {
                    coupon_id: 0,
                    goods_ids: [],
                    give_num: '',
                    limit_give_num: ''
                }
            },
            couponRules: {
                goods_ids: [{
                    required: true,
                    validator: validateGoods,
                    trigger: 'blur'
                }],
                give_num: [{
                    required: true,
                    validator: validateGiveNum,
                    trigger: 'blur'
                }],
                'sub_coupon.goods_ids': [{
                    required: true,
                    message: this.$t('seller.validator.selectGoods'),
                    trigger: 'blur'
                }],
                'sub_coupon.give_num': [{
                    required: true,
                    validator: validateSubGiveNum,
                    trigger: 'blur'
                }]
            },
            dialogVisibleGoods: false,
            activeName: '0',
            dialogGoodsNav: this.$t('seller.coupon.dialogGoodsNav'),
            shopCateOption: this.$t('seller.coupon.shopCateOption'),
            listLoading: false,
            dialogCoupon: 0,
            goodsType: 0,
            pageCurr: 1,
            pageSize: 5,
            pageTotal: 0,
            goodsList: [],
            checkIds: [],
            checkGoods: [],
            falgIds: [],
            multipleSelection: [],
            screenForm: {
                goods_code: '',
                goods_id: '',
                goods_name: '',
                store_cate_id: '0',
            },
        }
    },
    created() {
        this.getCouponInfo()
    },
    methods: {
        getCouponInfo() {
            let loading = this.$loading();
            fetchCoupon({ coupon_id: this.$route.params.id }).then(response => {
                const { data } = response
                this.couponInfo = data
                if (data.goods && data.goods.length > 0) {
                    let goods_ids = []
                    data.goods.forEach(item => {
                        goods_ids.push(item.goods_id)
                    })
                    this.couponInfo.goods_ids = goods_ids
                    this.couponForm.goods_ids = goods_ids
                }
                this.couponForm.coupon_id = data.coupon_id
                this.couponForm.give_num = data.give_num
                this.couponForm.limit_give_num = data.limit_give_num

                if (data.sub_coupon.goods && data.sub_coupon.goods.length > 0) {
                    let goods_ids = []
                    data.sub_coupon.goods.forEach(item => {
                        goods_ids.push(item.goods_id)
                    })
                    this.couponInfo.sub_coupon.goods_ids = goods_ids
                    this.couponForm.sub_coupon.goods_ids = goods_ids
                }
                this.couponForm.sub_coupon.coupon_id = data.sub_coupon.coupon_id
                this.couponForm.sub_coupon.give_num = data.sub_coupon.give_num
                this.couponForm.sub_coupon.limit_give_num = data.sub_coupon.limit_give_num
                loading.close();
            }).catch(() => {
                loading.close();
                this.$router.push({ name: 'couponList' });
            })
        },
        getGoodsList() {
            this.listLoading = true
            let param = {
                ...this.screenForm,
                type: this.activeName,
                coupon_id: this.dialogCoupon,
                page_index: (this.pageCurr - 1) * this.pageSize,
                page_size: this.pageSize
            };
            getCouponGoods(param).then(response => {
                this.goodsList = response.data.list;
                this.pageTotal = parseInt(response.data.total);
                this.listLoading = false
                this.goodsList.forEach(item => {
                    if (this.checkIds.includes(item.goods_id)) {
                        this.$refs.goodsTable.toggleRowSelection(item)
                        this.checkIds.splice(this.checkIds.indexOf(item.goods_id), 1)
                    }
                })
            })
        },
        handleSizeChange(val) {
            this.pageSize = val;
        },
        handleCurrentChange(val) {
            this.pageCurr = val;
            this.getGoodsList();
        },
        handleSeach() {
            this.pageCurr = 1;
            this.getGoodsList();
        },
        handleGoodsShow(type) {
            this.goodsType = type
            if (type === 1) {
                this.dialogCoupon = this.couponForm.coupon_id
                this.checkIds = JSON.parse(JSON.stringify(this.couponForm.goods_ids))
                this.falgIds = JSON.parse(JSON.stringify(this.couponForm.goods_ids))
            } else if (type === 2) {
                this.dialogCoupon = this.couponForm.sub_coupon.coupon_id
                this.checkIds = JSON.parse(JSON.stringify(this.couponForm.sub_coupon.goods_ids))
                this.falgIds = JSON.parse(JSON.stringify(this.couponForm.sub_coupon.goods_ids))
            }
            this.pageCurr = 1
            this.activeName = '0'
            this.dialogVisibleGoods = true;
            this.$nextTick(() => {
                this.$refs.goodsTable.clearSelection()
                this.getGoodsList()
            })
        },
        handleCloseGoods() {
            this.dialogVisibleGoods = false;
        },
        handleClickGoods(tab, event) {
            this.pageCurr = 1;
            this.getGoodsList();
        },
        getRowKey(row) {
            return row.goods_id
        },
        onSelectable(row, index) {
            if (this.falgIds.includes(row.goods_id)) {
                return false;
            } else {
                return true;
            }
        },
        handleSelectionChange(val) {
            if (this.activeName !== 1) {
                this.checkGoods = val;
            }
        },
        handleToggleSelecting() {
            let ids = [];
            this.checkGoods.forEach(item => {
                ids.push(item.goods_id);
            })
            ids = this.checkIds.concat(ids)
            if (ids.length > 100) {
                this.$message.error(this.$t('seller.errorMsg.appointGoodsMax'))
                return false
            }
            if (this.goodsType === 1) {
                this.couponForm.goods_ids = ids
            } else if (this.goodsType === 2) {
                this.couponForm.sub_coupon.goods_ids = ids
            }
            this.dialogVisibleGoods = false
        },
        handleSave() {
            this.$refs.couponForm.validate(async (valid) => {
                if (valid) {
                    let loading = this.$loading();
                    const params = {
                        ...this.couponForm
                    }
                    params.goods_ids = params.goods_ids.join(',')
					params.sub_coupon = JSON.stringify(params.sub_coupon)
                    await updateCoupon(params)
                    this.$message.success(this.$t('seller.successMsg.save'));
                    this.$router.push({ name: 'couponList', query: { type: 3 } })
                    loading.close();
                } else {
                    return false;
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.page-bottom {
	margin-bottom: 20px;
}
.seller-dialog-scrollbar {
	height: 520px;
	.goods-item {
		display: flex;
		.pic {
			width: 60px;
			height: 60px;
			object-fit: cover;
		}

		.item-mian {
			flex: 1;
			width: 0;
			margin-left: 10px;
			line-height: 17px;

			.title {
				height: 34px;
				font-size: 14px;
				color: #666666;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.number {
				height: 17px;
				margin-top: 9px;
				color: #333333;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>
